<template>
  <section id="bloquePonentesPrincipal">
    <!-- hay que meter el div del bucle, queda a la espera del servicio, se ha repetido el div para maquetar -->
    <div class="bannerFoto">
      <img
        class="bannerFoto__imgLogoHome"
        src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/cabeceraponentes.jpg"
        alt=""
      />
    </div>
    <div class="contenedorPonentes">
      <p class="contenedorPonentes__texto">PONENTES</p>
      <div class="gridponentes">
        
          <a v-for="ponente in datos" :key="ponente" class="ponente" :href="`/ponentes/${ponente.slug}`">
            <img class="imgPonente"
              :src="ponente.img"
            />
            <div class="textos">
              <img class="textos__fondo"
              src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/name.jpg"
            />
              <p class="textos__nombrePonente">{{ponente.nombre}}</p>
            </div>
          </a>
        
      
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity';
  export default {
    name: "ContenidoPonentes",
    props: {
      dataponentes: Object,
    },
    setup(props) {
      console.log(props)
      const datos = ref(props.dataponentes);
      return { datos };
    },
    data: {
        contador: 0,
    }
  };
</script>