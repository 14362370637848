<template id="error__general">
  <section>
    <div class="error__general--div">
      <img
        class="error__general--logo"
        src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/logo-home.png"
        alt="Logo Knotgroup"
      />
      <p class="error__general--p">Ups.. Parece que algo va mal.</p>
      <p class="error__general--p">Estamos trabajando en ello.</p>
      <p class="error__general--p">Si el error persiste, por favor póngase en contacto con nosotros en: info-test@alebateducation.com</p>
    </div>
  </section>
</template>