<template> 
<MenuInterno/>
  <div v-if="errored" class="error">
    <p class="fontMedium cta_1">
      Lo sentimos, no es posible obtener la información en este momento, por
      favor intente nuevamente más tarde.
    </p>
  </div>

  <div v-if="loading" class="cargando">
    <p class="fontMedium cta_1">Cargando...</p>
  </div>
  <div v-else>
  <section id="bloquePonentes">  
    <ContenidoPonentes :dataponentes="datos"/>   
  </section> 
<FooterInterno/>
</div>
</template>

<script>
import ContenidoPonentes from "@/components/contenidoPonentes/contenidoPonentes.vue";
import MenuInterno from "@/components/menuInternas/menuInternas.vue";
import FooterInterno from "@/components/footerInternas/footerInternas.vue"
import axios from "axios";
import { ref } from '@vue/reactivity';

export default {
  name: "Ponentes principal",
  components: {
    MenuInterno,
    ContenidoPonentes,
    FooterInterno
  },
  inject: ['urlPeticion'],  
  data() {
    return {
      loading: true,
      errored: false,
      
    };
  },
  setup() {
    const datos = ref(null);
    return{datos}
  },
  created() {    
    this.getDatos();
  },

  methods: {
    async getDatos() {
      let urlJson = `${this.urlPeticion}/api/getPonentes/3`;
      await axios
        .get(urlJson)
        .then((response) => {
          this.datos = response.data;
        })
        .catch((error) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
