<template>
  <img class="bannerFoto"
    src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/cabeceraponentes.jpg" alt="" />
  <div class="tituloPonentes">
    <h2>Ponentes</h2>
  </div>

  <div class="detallesSobrePonente">
    <div class="detallesSobrePonente__imagenYDetallesPonencia">
      <div class="imagenDelPonente">
        <picture class="imagenDelPonente__ponentePicture">
          <img :src="datos.img"
            class="imagenDelPonente__ponentePicture--imgPonente" alt="imagen ponente" />
        </picture>
      </div>
      <div class="detallesSobrePonente__imagenYDetallesPonencia--botones">
        <p @click="cambiarVistaDetalles()">
          <img
            src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/microsites/iconos/informacion.png"
            alt="" />
          <i :class="{verDetalles : detallesNegrita}">DETALLE PONENCIA</i>
        </p>
        <hr>
        <p @click="cambiarVistaCV()">
          <img
            src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/microsites/iconos/informacion.png"
            alt="" />
          <i :class="{verCV : cvNegrita}">VER CV</i>
        </p>
        <br><br>
        <button @click="goToPonentes()"><img
            src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/microsites/iconos/flecha-volver-ponentes.png"
            alt="">
        </button>
      </div>
    </div>
    <div class="detallesSobrePonente__informacionPonente">
      <h2>{{datos.nombre}}</h2>
      <div class="detallesSobrePonente__informacionPonente--cv" :class="{cvReactivo : cv}">
        <ul>
          <li v-for="(parrafo, index) in datos.cv" :key="index">{{parrafo}}</li>
        </ul>
      </div>
      <div class="detallesSobrePonente__informacionPonente--detallesPonencia" :class="{detallesReactivo : detalles}">
        <p v-for="(ponencia, index) in datos.ponencias" :key="index">{{ponencia}}</p>
      </div>

    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {

  name: "detalles ponente principal",
  data() {
    return {
      cv : true,
      detalles: false,
      cvNegrita: false,
      detallesNegrita: true,
      contador: 0,
    }
  }, 
  props: {
      datadetalleponente: Object,
  },
  methods: {
    cambiarVistaCV(){
      this.cv = false
      this.detalles=true
      this.cvNegrita = true
      this.detallesNegrita = false
    },
    cambiarVistaDetalles(){
      this.cv = true
      this.detalles=false
      this.cvNegrita = false
      this.detallesNegrita = true
    },
    goToPonentes(){
      this.$router.push('/ponentes');
    }
  },
    setup(props) {
      console.log(props)
      const datos = ref(props.datadetalleponente);
      return { datos };
    },
   
  }

</script>