<template>
  <MenuInterno />
  <section v-if="error">
    <Error/>
  </section>
  <section v-if="loading">
    <Loading/>
  </section>
  <section v-else id="informacionBloque">
      <ContenidoInformacion :informacion="data" />
  </section>
  <FooterInterno />
</template>

<script>
import { useQuery } from '@vue/apollo-composable';
import Paginas from '../graphql/Queries.gql';
import { computed } from 'vue';


import MenuInterno from "@/components/menuInternas/menuInternas.vue";
import ContenidoInformacion from "@/components/informacion/Informacion.vue";
import FooterInterno from "@/components/footerInternas/footerInternas.vue";
import Loading from "@/components/loading/Loading.vue";
import Error from "@/components/error/Error.vue";

export default {
  name: "InformacionView",
  components: {
    MenuInterno,
    ContenidoInformacion,
    FooterInterno,
    Loading,
    Error
  },
  setup() {
    const { result, loading, error } = useQuery(Paginas.getInformacion);
    const data = computed(() => result.value?.getInformacion ?? [] );

    return {data,loading,error};
  }


};
</script>

<style>
</style>