<template>
    <MenuInterno/>
        <section id="bloqueComplementoPrivacidad">
            <ComplementoPrivacidadPrincipal/>
        </section>
    <FooterInterno/>
</template>
<script>
import MenuInterno from "@/components/menuInternas/menuInternas.vue";
import ComplementoPrivacidadPrincipal from '@/components/complementoPrivacidad/ComplementoPrivacidadPrincipal.vue'
import FooterInterno from "@/components/footerInternas/footerInternas.vue";

export default {
    name: "ComplementoPrivacidad",
    components: {
        MenuInterno,
        ComplementoPrivacidadPrincipal,
        FooterInterno,
    }
}
</script>