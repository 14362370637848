<template>
  <section id="bloqueProgramaPrincipal">
    <div class="bannerFoto">
      <img
        class="bannerFoto__imgLogoHome"
        src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/Programa/jpg+y+png/cabecera-programa.png"
        alt="cabecera de la sección de programa del congreso de Knotgroup 2023"
      />
    </div>
    <div class="todaProgramacion">
      <div class="textoDestacado">
        <h1 class="textoDestacado__masImportante">Programa</h1>
        <br />
        <p class="textoDestacado__menosImportante">27 DE MAYO DE 2023</p>
      </div>
      <div v-for="(sala, index) in datos" :key="sala" class="sala">
        <div class="sala__contenido">
            <h2 class="sala__titulo">{{ sala.titulo }}</h2>
            
        <div v-if="sala.titulo=='SALA TALLERES IPD'" class="sala_img">
              <picture>
                 <!-- <img :src="sala.url_logo" alt="" srcset="" />  -->
                <img src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/congreso/iconos/IPD.png" alt="">
              </picture>
        </div>
        </div>
        

        <div
          v-for="actividad in sala.ponencias"
          :key="actividad"
          class="sala__actividad"
          :class="[
            actividad.tipo == 9
              ? 'sala__actividad--azul'
              : [actividad.tipo == 3 ? 'sala__actividad--morado' : ''],
          ]"
        >
          <div v-if="actividad.tipo != 9" class="hora">
            <p>{{ actividad.fecha_inicio }}-{{ actividad.fecha_fin }}</p>
          </div>
          <div v-if="actividad.tipo == 3" class="info">
            <p class="info__texto">Punto de encuentro</p>
            <div v-if="actividad.url_logo != ''" class="info__TuEmpresa">
              <img
                v-if="actividad.url_logo != ''"
                height="60"
                :src="actividad.url_logo"
                alt="logo de la empresa"
              />
            </div>
          </div>
          <div v-else class="info">
            <div class="info_contenido">
              <p class="info__texto">{{ actividad.titulo }}</p>
              <br />
              <p class="info__texto">{{ actividad.descripcion }}</p>
              <br />
              <p class="info__ponentes">{{ actividad.ponentes }}</p>
            </div>

            <div class="info_img">
              <picture>
                <img :src="actividad.url_logo" alt="" srcset="" />
              </picture>
            </div>
          </div>
        </div>
        <div v-if="index < datos.length - 1" class="barraSeparadora"></div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BaseBlock",
  props: {
    dataprograma: Object,
  },
  setup(props) {
    const datos = props.dataprograma;
    return { datos };
  },
  data: {
    contador: 0,
  },
};
</script>