<template>        
    <MenuInternas/>
    <div v-if="errored" class="error">
        <p class="fontMedium cta_1">
        Lo sentimos, no es posible obtener la información en este momento, por
        favor intente nuevamente más tarde.
        </p>
    </div>

    <div v-if="loading" class="cargando">
        <p class="fontMedium cta_1">Cargando...</p>
    </div>
    <div v-else>
        <section id="detallesPonente">
        <DetallesPonente :datadetalleponente="datos"/>
        </section>
        <FooterInternas/>
    </div>
</template>

<script>

import MenuInternas from '@/components/menuInternas/menuInternas.vue'
import DetallesPonente from '@/components/detallesPonente/detallesPonenetePrincipal.vue'
import FooterInternas from '@/components/footerInternas/footerInternas.vue'
import { ref } from '@vue/reactivity';
import axios from 'axios';

export default {
    name: 'Detalles Ponente',
    components:{
        MenuInternas,
        DetallesPonente,
        FooterInternas
    },
    data() {
    return {
      loading: true,
      errored: false,
      
    };
  },
  inject: ['urlPeticion'],  
  setup() {
    const datos = ref(null);
    return{datos}
  },
  created() {
    this.getDatos();
  },

  methods: {
    async getDatos() {
      let urlJson = `${this.urlPeticion}/api/getPonenteBySlug/${this.$route.params.slug}`;
      await axios
        .get(urlJson)
        .then((response) => {
          this.datos = response.data;
        })
        .catch((error) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>