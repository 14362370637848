<template>
  <MenuInterno />
  <div v-if="errored" class="error">
    <p class="fontMedium cta_1">
      Lo sentimos, no es posible obtener la información en este momento, por
      favor intente nuevamente más tarde.
    </p>
  </div>

  <div v-if="loading" class="cargando">
    <p class="fontMedium cta_1">Cargando...</p>
  </div>
  <div v-else>
    <section id="bloquePrograma">
      <ContenidoPrograma :dataprograma="data"/>
    </section>
    <FooterInterno />
  </div>
</template>

<script>
import ContenidoPrograma from "@/components/contenidoPrograma/contenidoPrograma.vue";
import MenuInterno from "@/components/menuInternas/menuInternas.vue";
import FooterInterno from "@/components/footerInternas/footerInternas.vue";
import axios from "axios";

export default {
  name: "Programa principal",
  components: {
    MenuInterno,
    ContenidoPrograma,
    FooterInterno,
  },
  inject: ['urlPeticion'], 
  data() {
    return {
      loading: true,
      errored: false,
      data: null,
    };
  },

  created() {
    this.getDatos();
  },

  methods: {
    async getDatos() {
      let urlJson = `${this.urlPeticion}/api/getPonencias/3/`;
      await axios
        .get(urlJson)
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
