import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

import Ponentes from '../views/Ponentes'
import Programa from '../views/Programa'
import Privacidad from '../views/Privacidad'
import Cookies from '../views/Cookies'
import Informacion from "../views/Informacion"
import ComplementoPrivacidad from "../views/ComplementoPrivacidad"
import DetellesPonente from "../views/DetallesPonenete"
import notFound from '../views/notFound.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/ponentes',
    name: 'ponentes',
    component: () => import(/* webpackChunkName: "Ponentes" */ '../views/Ponentes.vue')
    
  },
  {
    path: '/ponentes/:slug',
    name: 'detalles ponente',
    component: DetellesPonente
  },
  {
    path: '/programa',
    name: 'programa',
    component: Programa
  },
  {
    path: '/informacion',
    name: 'informacion',
    component: Informacion
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/politica-de-privacidad',
    name: 'politica de privacidad',
    component: Privacidad
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies
  },
  {
    path: '/complemento-privacidad',
    name: 'complemento privacidad',
    component: ComplementoPrivacidad
  },  
  {
    path:'/:pathMatch(.*)*',
    component: notFound
  },
   {
     path: '/gracias',
     name: 'PaginaGracias',
     component: () => import(/* webpackChunkName: "about" */ '../views/PaginaGracias.vue')
   }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
