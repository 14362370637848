<template>  
  <router-view/>
  <MensajeCookies/>
</template>

<script>

import {mapActions} from 'vuex';
import MensajeCookies from '@/components/Cookies/mensajeCookies.vue'

export default {
  name: 'App',
  components: {MensajeCookies},
  methods: {
   ...mapActions('cambioImagenes',['informacionNavegador']),
  },
  created() { 
    this.informacionNavegador(); 
  }
}
</script>