<template> 
<MenuInterno/>
  <section id="bloquePrivacidad">  
     <PrivacidadPrincipal/>
  </section> 
<FooterInterno/>
</template>

<script>
import MenuInterno from "@/components/menuInternas/menuInternas.vue";
import PrivacidadPrincipal from "@/components/privacidad/privaciadadPrincipal.vue";
import FooterInterno from "@/components/footerInternas/footerInternas.vue";

export default {
  name: "Privacidad",
  components: {
    MenuInterno,
    PrivacidadPrincipal,
    FooterInterno,
  },
};
</script>