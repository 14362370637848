<template> 
  <section id="bloque404">  
     <notFound/>
  </section> 
</template>

<script>
import notFound from "@/components/notFound/notFound.vue";

export default {
  name: "Page404",
  components: {
    notFound,
  },
};
</script>