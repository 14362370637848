<template>
  <div id="footer" class="footer_internas">
    <img
      class="footer_internas__logo"
      src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/logo-home.png"
      alt="logo Knotgroup"
    />
    <p class="footer_internas__p">
      Knotgroup<br>
      <strong>Oral Medicine,<br>
      inside.</strong>
    </p>
    <p class="footer_internas__privacidad">
      <a class="footer_internas__privacidad--a" href="/cookies">Política de Cookies</a>
      <a class="footer_internas__privacidad--a" href="/politica-de-privacidad">Política de privacidad</a>
    </p>

  </div>
</template>
