<template> 
<MenuInterno/>
  <section id="bloqueCookies">  
     <CookiesPrincipal/>
  </section> 
<FooterInterno/>
</template>

<script>
import MenuInterno from "@/components/menuInternas/menuInternas.vue";
import CookiesPrincipal from "@/components/Cookies/cookiesPrincipal.vue";
import FooterInterno from "@/components/footerInternas/footerInternas.vue";

export default {
  name: "Cookies",
  components: {
    MenuInterno,
    CookiesPrincipal,
    FooterInterno,
  },
};
</script>