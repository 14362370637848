<template>
  <img
    class="bannerFoto"
    src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/fotos/cabeceraponentes.jpg"
    alt=""
  />
  <div class="complementoPrivacidad">
    <h1 class="complementoPrivacidad__titulo">
      Política de Privacidad. Documento Específico de Derechos de Imágen y
      Cesión de Datos a Terceros.
    </h1>
    <p>
      Como complemento a la Política de Privacidad de KNOT GROUP DENTAL
      CORPORATION, SL que ha aceptado expresamente al inicio del proceso de
      acceso a nuestra web, se especifica aquí la información, aceptación y
      condiciones para los asistentes al III Congreso de Knot Group Los
      Orígenes, que se celebrará en mayo de 2023.
    </p>
    <p>
      Así, el asistente al Congreso AUTORIZA a KNOT GROUP DENTAL CORPORATION, ,
      SL a la utilización de su nombre y de las fotografías y/o vídeos que le
      han sido realizadas en el desarrollo del mismo, para su publicación con
      fines educativos y promocionales en la página web de la compañía, así como
      en otros documentos digitales o en papel generados por la misma. Dichos
      vídeos y/o fotografías podrán utilizarse en los materiales de marketing
      digitales o en papel de la compañía.
    </p>
    <p>
      KNOT GROUP DENTAL CORPORATION, S.L., en cumplimiento con el compromiso
      recogido en el apartado 7.4 de su Política de Privacidad, indica la
      identidad de terceros co-responsables del tratamiento en la cesión de los
      datos de asistentes a congresos o jornadas profesionales. Las empresas
      colaboradoras en el "III Congreso de Knot Group Los Orígenes" reciben
      estos datos para fines promocionales y de márketing de sus productos y
      servicios por el tiempo imprescindible para realizar esta labor en base al
      consentimiento aquí recogido. Los colaboradores son:
    </p>
    <p>
      -Empresa, dirección, responsable del tratamiento de datos y contacto para
      ejercitar los derechos ARCO.
    </p>
  </div>
</template>