<template>
  <nav id="menu" class="menu">
    <div class="menu__bloque">
      <div @click="hamburguesa()" class="menu__icono menu__icono--izquierda">
        <span class="activo1"></span>
        <span class="activo2"></span>
        <span class="activo3"></span>  
      </div>
      <span class="menu__bloque--span">MENU</span>
    </div>
    
    <div class="menu__bloque">
      <span class="menu__bloque--span">PRESENTACIÓN</span>
      <div @click="cruz()" class="menu__cruz menu__icono--derecha">
        <span class="cruz_linea_1 activo_cruz_1"></span>
        <span class="cruz_linea_2 activo_cruz_2"></span>
      </div>
    </div>
    
  </nav>

  <div class="menu__desplegable_izquierda">
    <ul class="informacion">
      <li class="informacion__titulo">
        <a title="informacion Knotgroup" href="/informacion" class="informacion__titulo--a">INFORMACIÓN</a>
      </li>
      <li class="informacion__li">
        <a title="origenes Knotgroup" href="/informacion" class="informacion__a">Los Orígenes</a>
      </li>
      <li class="informacion__li">
        <a title="comite organizador Knotgroup" href="/informacion/#ancla_comiteCientifico" class="informacion__a"
          >Comité Científico</a>
      </li>
      <li class="informacion__li">
        <a title="comite organizador Knotgroup" href="/informacion/#ancla_comite" class="informacion__a"
          >Comité Organizador</a
        >
      </li>
    </ul>
    <ul class="informacion__segundo">
      <li class="informacion__li--segundo">
        <a title="programa Knotgroup" href="/programa" class="informacion__a informacion__a--segundo"
          >PROGRAMA</a
        >
      </li>
      <li class="informacion__li--segundo">
        <a title="ponentes Knotgroup" href="/ponentes" class="informacion__a informacion__a--segundo"
          >PONENTES</a
        >
      </li>
     <!-- <li class="informacion__li--segundo">
        <a title="inscripciones Knotgroup" href="/" class="informacion__a informacion__a--segundo"
          >INSCRIPCIONES</a
        >
      </li>-->
    </ul>
  </div>

  <div class="menu__desplegable_derecha">
    <div class="menu__desplegable_derecha__contenidos">
      <img
        class="informacion__logo"
        src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/logo-home.png"
        alt="Logo Knotgroup"
      />
      <h3 class="menu__desplegable_derecha__titulo">PRESENTACIÓN</h3>
      <p class="menu__desplegable_derecha__texto">
        Un Congreso único en el que descubriremos Los Orígenes de Knotgroup Dental Corporation.
        Hablaremos de la relación de la Odontología con otras especialidades médicas y, por supuesto,
        nos asomaremos a otras áreas esenciales de los Orígenes de todas las Clínicas Knotgroup:
        Medicina, Tecnología, Innovación y Equipos son nuestros principios, nuestra Razón de Ser. ¿Te lo vas a perder?
      </p>
      <video class="menu__desplegable_derecha__video" poster="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/congreso/fotos/portada-origenes.png" src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/congreso/videos/KG-congreso-intro.mp4" controls></video>
    </div>
  </div>
</template>


<script>
export default {
  name: "menu Home",

  methods: {
    hamburguesa() {
      var menu__linea1 = document.querySelector(".activo1");
      var menu__linea2 = document.querySelector(".activo2");
      var menu__linea3 = document.querySelector(".activo3");
      var cruz__linea_1 = document.querySelector(".activo_cruz_1");
      var cruz__linea_2 = document.querySelector(".activo_cruz_2");
      var menu__desplegable_izquierda = document.querySelector(".menu__desplegable_izquierda");
      var menu__desplegable_derecha = document.querySelector(".menu__desplegable_derecha");

      menu__linea1.classList.toggle("menu__linea--1");
      menu__linea2.classList.toggle("menu__linea--2");
      menu__linea3.classList.toggle("menu__linea--3");

   if (!menu__desplegable_izquierda.classList.contains("menu__desplegable_izquierda--activo")) {
        menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
        if(menu__desplegable_derecha.classList.contains("menu__desplegable_derecha--activo")){
          menu__desplegable_derecha.classList.toggle("menu__desplegable_derecha--activo");
          cruz__linea_1.classList.toggle("cruz_linea_1");
          cruz__linea_2.classList.toggle("cruz_linea_2");
          cruz__linea_1.classList.toggle("equis_linea_1");
          cruz__linea_2.classList.toggle("equis_linea_2");
        }
      } else {
        menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
      }
    },

    cruz() {
      var cruz__linea_1 = document.querySelector(".activo_cruz_1");
      var cruz__linea_2 = document.querySelector(".activo_cruz_2");
      var menu__linea1 = document.querySelector(".activo1");
      var menu__linea2 = document.querySelector(".activo2");
      var menu__linea3 = document.querySelector(".activo3");
      var menu__desplegable_derecha = document.querySelector(".menu__desplegable_derecha");
      var menu__desplegable_izquierda = document.querySelector(".menu__desplegable_izquierda");

      cruz__linea_1.classList.toggle("cruz_linea_1");
      cruz__linea_2.classList.toggle("cruz_linea_2");
      cruz__linea_1.classList.toggle("equis_linea_1");
      cruz__linea_2.classList.toggle("equis_linea_2");

      if (!menu__desplegable_derecha.classList.contains("menu__desplegable_derecha--activo")) {
        menu__desplegable_derecha.classList.toggle("menu__desplegable_derecha--activo");
        if(menu__desplegable_izquierda.classList.contains("menu__desplegable_izquierda--activo")){
          menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
          menu__linea1.classList.toggle("menu__linea--1");
          menu__linea2.classList.toggle("menu__linea--2");
          menu__linea3.classList.toggle("menu__linea--3");
        }
      } else {
        menu__desplegable_derecha.classList.toggle("menu__desplegable_derecha--activo");
      }
      
    },
  },
};
</script>