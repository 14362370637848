<template>
  <section v-if="error">
    <Error/>
  </section>
  <section v-if="loading">
    <Loading/>
  </section>
  <section v-else id="home">  
    <MenuHome/>
    <ContenidoHome/>        
    <FooterHome :footer="jsonHome.footer"/> 
  </section> 
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import { useQuery } from '@vue/apollo-composable';
import Paginas from '../graphql/Queries.gql';
import { computed } from 'vue';


import MenuHome from '@/components/menuHome/MenuHomePrincipal.vue';
import ContenidoHome from '@/components/contenidoHome/ContenidoHome.vue';
import FooterHome from '@/components/footerHome/FooterHomePrincipal.vue';
import Loading from "@/components/loading/Loading.vue";
import Error from "@/components/error/Error.vue";

export default {
  name: 'HomeView',
  components: {
    //HelloWorld,
    MenuHome,
    ContenidoHome,
    FooterHome,
    Loading,
    Error
  },
    setup() {
    const { result, loading, error } = useQuery(Paginas.getHome);
    const jsonHome = computed(() => result.value?.getHome ?? [] )

    return {jsonHome,loading,error};
  }
   
}
</script>
