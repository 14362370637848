<template>
  <section id="bloqueHome">
    <div class="contenidoHome">
      <div class="contenidoHome__contenedorIzq">
        <div class="filaUno">
          <picture>
            <img
              class="imgLogoHome"
              src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/logo-home.png"
              alt=""
            />
          </picture>
        </div>
        <div class="filaDos">
          <p class="tituloIzq">
            Estás a un paso de descubrir el III Congreso Knotgroup <br>"Los Orígenes"
          </p>
        </div>
        <div class="filaTres">
          <a class="ajusteenlace" href="/programa">
            <picture>
              <img
                class="imgLogos"
                src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/ico-programa.png"
                alt=""
              />
            </picture>
            <p class="tituloIcono">VER PROGRAMA</p>
          </a>
          <a class="ajusteenlace" href="/ponentes">
            <picture>
              <img
                class="imgLogos"
                src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/ico-ponentes.png"
                alt=""
              />
            </picture>
            <p class="tituloIcono">VER PONENTES</p>
          </a>
        </div>
        
        <div class="filaCinco">
          <picture>
            <img
              src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/ico-sponsors.png"
              alt=""
            />
          </picture>
          <div class="cajaTexto">
            <div class="txtIcono">
              <h4 class="tituloIcono">SPONSORS</h4>
            </div>
            <div class="cajaTexto__flecha">
              <img
                class="flechaBlanca"
                src="https://d5uuf868kzhnu.cloudfront.net/wp-content/uploads/2021/12/14101236/flecha_pc.png"
                alt="Flecha Blanca Knotgroup"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="contenidoHome__contenedorDrcha">
        <div class="textoDerecha">
          <p class="textoDerecha__texto">
            Medicina, Digitalización, Tecnología, Equipos, Paciente, Nuestra
            Razón de Ser.
          </p>
          <p class="textoDerecha__subtexto">
            Inscríbete al Congreso, cuéntanos si nos acompañaras a la cena y si necesitas desplazamiento.
          </p>
        </div>
        <!-- Formulario de zoho con nuestros estilos -->
        <form
          action="https://forms.zohopublic.com/inspiriadental/form/inscripcincongresoonlinekg2023/formperma/AKbu7zTjOB1Tvi5M_jnKtyS5QPRIdDquulWV-r5vnoA/htmlRecords/submit"
          name="form"
          id="form"
          method="POST"
          accept-charset="UTF-8"
          enctype="multipart/form-data"
          class="formularioContacto"
        >
          <input
            type="hidden"
            name="zf_referrer_name"
            value=""
          /><!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
          <input
            type="hidden"
            name="zf_redirect_url"
            value=""
          /><!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
          <input
            type="hidden"
            name="zc_gad"
            value=""
          /><!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
          <!--Single Line-->
          <div class="inputNomApes">
            <input
              type="text"
              name="SingleLine"
              fieldType="1"
              v-model="nombreApellidos"
              class="sinFondo"
              placeholder="Nombre y apellidos"
              required
              pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
              title="Inserte su nombre y apellidos. Ejemplo: [María Casas Pérez]"
            />
          </div>
          <!--Single Line-->
          <div class="inputEmail">
            <input
              type="text"
              name="SingleLine2"
              fieldType="1"
              maxlength="255"
              v-model="email"
              class="sinFondo"
              placeholder="Email"
              required
              pattern="^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
              title="Inserte un correo electrónico válido (Ej.: prueba@gmail.com)"
            />
          </div>
          <div class="juntos">
            <!--Single Line-->
            <div class="inputTelf">
              <input
                type="text"
                name="SingleLine1"
                fieldType="1"
                v-model="telefono"
                class="sinFondo mitadTelf"
                placeholder="Teléfono personal"
                required
                pattern="[0-9]{9}"
                title="Inserte un número de teléfono válido (Ej.: 430543528)"
              />
            </div>
            <!--Single Line-->
            <div class="inputClinica">
              <input
                type="text"
                name="SingleLine3"
                fieldType="1"
                v-model="nombreClinica"
                class="sinFondo mitadClinica"
                placeholder="Clínica"
                required
                pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1.]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1.]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1.]+$"
                title="Inserte el nombre de su clínica"
              />
            </div>
          </div>

          <!--Single Line -->
          <div>
            <input
              type="hidden"
              name="SingleLine4"
              value="online"
              fieldType="1"
            />
          </div>

          <!--Decision Box-->
          <div class="checkboxTextosLegales">
            <input
              type="checkbox"
              id="DecisionBox2"
              name="DecisionBox2"
              class="checkbox-round"
              required
            />
            <label for="DecisionBox2">He leido y acepto la <a class="enlacepolitica" href="/politica-de-privacidad">política de privacidad </a> y  <a class="enlacecookies" href="/cookies"> protección de datos </a></label>
          </div>
          <div class="botonIncribete">
            <button type="submit" class="btnSubmit" value="Submit">
              INSCRÍBETE
            </button>
          </div>
        </form>
        <!-- Fin de formulario de zoho con nuestros estilos -->

        <div class="cajaSponsors">
          <div class="imgSponsor">
            <picture>
              <img
                src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/ico-sponsors.png"
                alt=""
              />
            </picture>
          </div>
          <div class="cajaTexto">
            <div class="txtIcono">
              <h4 class="tituloIcono">SPONSORS</h4>
            </div>
            <div class="cajaTexto__flecha">
              <img
                class="flechaBlanca"
                src="https://d5uuf868kzhnu.cloudfront.net/wp-content/uploads/2021/12/14101236/flecha_pc.png"
                alt="Flecha Blanca Knotgroup"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import axios from "axios";

export default {
  name: "ContenidoHome",

  methods: {
    solocena() {
      var cena = document.getElementById("DecisionBox");
      var cenaytransporte = document.getElementById("DecisionBox1");
      if (cena.checked) {
        //console.log('Ha elegido solo cena')
        cenaytransporte.checked = false;
      }
    },

    cenaytransporte() {
      var cena = document.getElementById("DecisionBox");
      var cenaytransporte = document.getElementById("DecisionBox1");
      if (cenaytransporte.checked) {
        //console.log('Ha elegido cena y transporte')
        cena.checked = false;
      }
    },
  },

  data() {
    return {
      nombreApellidos: "",
      email: "",
      telefono: "",
      nombreClinica: "",
      cena: "No marcado",
      cenayTransporte: "No marcado",
    };
  },
};
</script>