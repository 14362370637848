<template>
  <div id="footer" class="footer">
    <div class="footer__slider">
      <div class="footer__slideTrack">
        <div v-for="item in datos.sponsors" :key="item" class="footer__slide">
          <img
            v-bind:alt="item.alt"
            v-bind:src="item.url"
            class="footer__slide--img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerHomePrincipal",
  props: {
    footer: Object,
  },
  setup(props) {
    const datos = props.footer;
    return { datos };
  },
};
</script>