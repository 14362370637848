<template>
  <nav id="menuInterno" class="menuInterno">
    <div class="menu__bloque_internas ocultar_en_pc">
      <div @click="hamburguesa()" class="menu__icono menu__icono--izquierda">
        <span class="activo1"></span>
        <span class="activo2"></span>
        <span class="activo3"></span>  
      </div>
      <span class="menu__bloque_internas--span">MENU</span>
    </div>
    
    <div class="menu__bloque_internas">
      <span class="menu__span">HOME</span>
      <a title="Home Knotgroup" href="/"><img class="imgagen__home" src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/congreso/iconos/ico-menu.png" alt="Logo Home"></a>
    </div>

    <div class="menuInterno__menu_pc">
      <ul class="internas_informacion__segundo--pc">         
        <li class="internas_informacion__li--segundo--pc">
          <a title="internas_informacion Knotgroup" href="/informacion" class="internas_informacion__a internas_informacion__a--segundo"
            >INFORMACIÓN</a
          >
        </li>
        <li class="internas_informacion__li--segundo--pc">
          <a title="programa Knotgroup" href="/programa" class="internas_informacion__a internas_informacion__a--segundo"
            >PROGRAMA</a
          >
        </li>
        <li class="internas_informacion__li--segundo--pc">
          <a title="ponentes Knotgroup" href="/ponentes" class="internas_informacion__a internas_informacion__a--segundo"
            >PONENTES</a
          >
        </li>     
      </ul>
    </div>

    <div class="ocultar_en_movil">
      <a title="Home Knotgroup" href="/"><img src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/microsites/iconos/ico-logomenu.png" alt="logo_origen" class="logo_peq__img"></a>
    </div>
    
  </nav>

  <div class="subMenu">
    <div class="subMenu__div">
      <p class="subMenu__p">¿Todavía no te has inscrito? <br class="ocultar_en_pc">¡A qué esperas!</p>
      <a title="home Knotgroup" class="subMenu__a" href="/"><span class="subMenu__span">INSCRÍBETE</span></a>
    </div>
    <div class="subMenu__div_cerrar">
      <img @click="cerrar_subMenu()" class="subMenu__cerrar" src="https://congresos-inspiria.s3.eu-west-1.amazonaws.com/knotgroupOrigenes/congreso/iconos/ico-cerrar.png" alt="Icono Cerrar">
    </div>
  </div>

  <div class="menu__desplegable_izquierda ocultar_en_pc">
    <ul class="informacion">
      <img
        class="informacion__logo"
        src="https://d3cxxr51u7qjth.cloudfront.net/knotgroupOrigenes/microsites/iconos/logo-home.png"
        alt="Logo Knotgroup"
      />
      <li class="internas_informacion__titulo">
        <a title="internas_informacion Knotgroup" href="/informacion" class="internas_informacion__titulo--a">INFORMACIÓN</a>
      </li>
      <li class="internas_informacion__li">
        <a title="origenes Knotgroup" href="/informacion" class="internas_informacion__a">Los orígenes</a>
      </li>
      <li class="internas_informacion__li">
        <a title="comite organizador Knotgroup" href="/informacion/#ancla_comite" class="internas_informacion__a"
          >Comité Organizador</a
        >
      </li>
      <li class="internas_informacion__li">
        <a title="sede Knotgroup" href="/informacion/#ancla_sede" class="internas_informacion__a">Sede</a>
      </li>
      <li class="internas_informacion__li">
        <a title="vigo Knotgroup" href="/informacion/#ancla_vigo" class="internas_informacion__a">Vigo</a>
      </li>
      <li class="internas_informacion__li">
        <a title="cena Knotgroup" href="/informacion/#ancla_cena" class="internas_informacion__a">Cena</a>
      </li>
      <li class="internas_informacion__li">
        <a title="descuentos Knotgroup" href="/informacion/#ancla_descuentos" class="internas_informacion__a">Descuentos</a>
      </li>
    </ul>
    <ul class="internas_informacion__segundo">
      <li class="internas_informacion__li--segundo">
        <a title="programa Knotgroup" href="/programa" class="internas_informacion__a informacion__a--segundo"
          >PROGRAMA</a
        >
      </li>
      <li class="internas_informacion__li--segundo">
        <a title="ponentes Knotgroup" href="/ponentes" class="internas_informacion__a informacion__a--segundo"
          >PONENTES</a
        >
      </li> 
    </ul>
  </div>
</template>


<script>
export default {
  name: "menu internas",

  methods: {
    hamburguesa() {
      var menu__linea1 = document.querySelector(".activo1");
      var menu__linea2 = document.querySelector(".activo2");
      var menu__linea3 = document.querySelector(".activo3");
      var menu__desplegable_izquierda = document.querySelector(".menu__desplegable_izquierda");
      

      menu__linea1.classList.toggle("menu__linea--1");
      menu__linea2.classList.toggle("menu__linea--2");
      menu__linea3.classList.toggle("menu__linea--3");

   if (!menu__desplegable_izquierda.classList.contains("menu__desplegable_izquierda--activo")) {
        menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
      } else {
        menu__desplegable_izquierda.classList.toggle("menu__desplegable_izquierda--activo");
      }
    },

    cerrar_subMenu() {
      var subMenu = document.querySelector(".subMenu");
      subMenu.style.opacity = "0";
      subMenu.style.transition = "all 1s";
      setTimeout(() => {
        subMenu.style.display = "none";
      }, 1000);      
    },

  },
};
</script>